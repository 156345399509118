import React from "react";
import { Link } from "gatsby";

const Commercial = ({
  mobileImage,
  mobileImageWebp,
  image,
  imageWebp,
  commercialText,
  linkText,
}) => {
  return (
    <section className="commercial commercial--ampm">
      <div className="commercial__image-wrapper">
        <picture>
          <source
            type="image/webp"
            media="(max-width: 1024px)"
            srcSet={mobileImageWebp}
          />
          <source media="(max-width: 1024px)" srcSet={mobileImage} />
          <source type="image/webp" srcSet={imageWebp} />
          <img src={image} alt="Вдохновляющий дизайн" />
        </picture>
      </div>
      <div className="commercial__wrapper wrapper">
        <div className="commercial__text-wrapper">
          <p>{commercialText}</p>
          <Link
            to="https://ampm.ru/"
            className="link-button link-button--white commercial__link-button"
          >
            {linkText}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Commercial;
