import React from "react";

const Text = ({ titleText, bigText }) => {
  return (
    <section className="text">
      <div className="text__wrapper wrapper">
        <h2 className="text__title title-text">{titleText}</h2>
        <p className="text__big big-text big-text--black" dangerouslySetInnerHTML={{__html:bigText}} />
      </div>
    </section>
  );
};

export default Text;
