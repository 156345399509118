import React from "react";

const Featured = ({
  sectionClass,
  imageMobile,
  image,
  featuredTitle,
  featuredText,
  titleClass,
  textClass,
}) => {
  return (
    <section
      className={sectionClass ? `featured ` + sectionClass : `featured`}
      id="second"
    >
      <div className="featured__image-wrapper image-wrapper">
        <picture>
          <source media="(max-width: 991px)" srcSet={imageMobile} />
          <img src={image} alt="Культовый дизайн продукта" />
        </picture>
      </div>
      <div className="featured__wrapper wrapper">
        <div className="featured__text-wrapper">
          <h2 className={titleClass ? `big-text ` + titleClass : `big-text`} dangerouslySetInnerHTML={{__html:featuredTitle}} />
          <p
            className={
              textClass ? `featured__text ` + textClass : `featured__text`
            }
          >
            {featuredText}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Featured;
